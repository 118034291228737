<template>
  <div class="event">
    <div class="banner">
    </div>
    <div class="info col-11 col-xl-9 mx-auto">
      <div class="title">短影音行銷，已成為時代趨勢</div>
      <p>還在用傳統數位行銷嗎? <br>
受制於外來平台，不如自己開創流量 短影音+電商的整合方案</p>
      <div class="row">
        <div class="col-12 col-md-3 mx-auto my-5">
          <img class="w-100" :src="require('../assets/event/pic_1.png')" alt="">
          <div class="text">
            <div class="card_title">短影音行銷趨勢</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="col-12 col-md-3 mx-auto my-5">
          <img class="w-100" :src="require('../assets/event/pic_2.png')" alt="">
          <div class="text">
            <div class="card_title">嗨店功能與應用</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="col-12 col-md-3 mx-auto my-5">
          <img class="w-100" :src="require('../assets/event/pic_3.png')" alt="">
          <div class="text">
            <div class="card_title">線上獨家優惠</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="informationForm">
      <div class="col-11 col-xl-9 mx-auto">
        <div class="title">【立刻免費報名 開店講座】</div>
        <div class="subtitle">掌握流量新趨勢 全方位影音商店</div>

        <ul>
          <p>報名場次(詳細報名資訊將由專人聯繫通知)</p>
          <div class="text-start my-3">
            <el-select class="w-100" v-model="form.event" placeholder="活動場次">
              <el-option
                v-for="item in eventsList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <p>時間無法配合【請專人合作洽詢】</p>
        </ul>
        <div class="row information">
          <div class="col-12 col-md-6">
            <span class="d-block">公司名稱</span>
            <el-input v-model="form.companyName" placeholder="" maxlength="30"></el-input>
          </div>
          <div class="col-12 col-md-6">
            <span class="d-block">統一編號</span>
            <el-input v-model="form.taxId" maxlength="8"></el-input>
          </div>
          <div class="col-12 col-md-6">
            <span class="d-block">聯絡人姓名</span>
            <el-input v-model="form.name" maxlength="10"></el-input>
          </div>
          <div class="col-12 col-md-6">
            <span class="d-block">手機號嗎</span>
            <el-input v-model="form.telephone" maxlength="12"></el-input>
          </div>
          <div class="col-12 col-md-6">
            <span class="d-block">email</span>
            <el-input v-model="form.email" maxlength="50"></el-input>
          </div>
          <!-- <div class="col-6">
            <el-select class="w-100" v-model="form.person" placeholder="參加人數">
              <el-option
                v-for="item in personOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div class="col-12 col-md-6">
            <span class="d-block">商品類型</span>
            <el-select class="w-100" v-model="form.saleCategory" placeholder="販售商品類型">
              <el-option
                v-for="item in saleCategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="terms_send">
          <el-checkbox v-model="checked">
            已閱讀並同意
            <a href="https://www.hi8.tw/article?id=4" target="_blank">商城服務條款</a>
            及
            <a href="/private" target="_blank">隱私權政策</a>
          </el-checkbox>
          <el-button class="w-100 my-3" type="primary" @click="send">確認送出</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      form:{
        event:"",
        companyName:"",
        taxId:"",
        name:"",
        telephone:"",
        email:"",
        // person:"",
        saleCategory:"",
      },
      // personOptions:[
      //   {
      //     value:1,
      //     label:"1人"
      //   },
      //   {
      //     value:2,
      //     label:"2人"
      //   },
      //   {
      //     value:3,
      //     label:"3人以上"
      //   },
      // ],
      saleCategoryOptions:[],
      eventsList:[
        {
          label:"【線上場】4/15 (五) 13:30-15:00",
          value:1,
        },
        {
          label:"【線上場】4/29 (五) 13:30-15:00",
          value:2,
        }
      ],
      checked:false,
    }
  },

  mounted(){
    const vm = this 
    const url = "/hanchu_api/event/getCategory";
    this.axios
      .get(url)
      .then(function (response) {
        if (response.data.status) {
          let arr = []
          response.data.category.forEach(function(item){
            arr.push(
              {
                value : item.category_id,
                label : item.name 
              }
            )
          })
          vm.saleCategoryOptions = arr
        }else{
          vm.$message.error(response.data.msg);
        }
      })
      .catch(function (error) {
        alert(error);
      });
  },
  watch: {
    "form.taxId": function(newValue) {
      this.form.taxId = newValue.replace(/\D/g, '').replace(/\b(0+)/gi,"")
    },
    "form.telephone": function(newValue) {
      this.form.telephone = newValue.replace(/\D/g, '').replace(/\b(0+)/gi,"")
    },
  },
  methods:{
    errorMsg:function(str){
      this.$message({
        duration: 5000 ,
        type:"error",
        message: str,
        customClass:"h4"
      });
    },
    send:function(){
      const vm = this 
      let regex = new RegExp(/^[^@\s]+@[^@\s]+\.[^@\s]+$/);
      if (!this.form.event) {
        this.errorMsg('請選擇活動場次');  
      } else if (!this.form.companyName) {
        this.errorMsg('請輸入公司名稱');
      } else if (!this.form.taxId) {
        this.errorMsg('請輸入統一編號');
      } else if (!this.form.name) {
        this.errorMsg('請輸入聯絡人姓名');
      } else if (!this.form.telephone || this.form.telephone.length < 9) {
        this.errorMsg('請輸入聯絡電話');
      } else if (!this.form.email || !regex.test(this.form.email)) {
        this.errorMsg('請輸入正確的email格式');
      } else if (!this.checked) {
        this.errorMsg('請同意隱私權政策');
      } else{
        let loader = this.$loading.show({
          onCancel: this.yourMethodName,
          color: "#2575ed",
          loader: "spinner",
          width: 64,
          height: 64,
          backgroundColor: "#ffffff",
          opacity: 0.5,
        });

        const url = "/hanchu_api/event/signup";
        this.axios
          .post(url,vm.form)
          .then(function (response) {
            loader.hide();
            if (response.data.status) {
              Object.assign(vm.$data, vm.$options.data())
              vm.$message.success({
                duration: 5000 ,
                message:response.data.msg
              });
            }else {
              vm.errorMsg(response.data.msg);
            }
          })
          .catch(function (error) {
            loader.hide();
            alert(error);
          });
      }

    },
  }
};
</script>
<style lang="scss">
$main:#13356B;
.event{
    padding-bottom: 10rem;
    .banner{
      height: 55vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("~@/assets/event/banner.png");
    }
  .title{
    color:$main;
    font-size: 42px;
    font-weight: bold;
  }
  p{
    font-size: 16px;
  }
  .info{
    padding: 200px 0;
    p{
      padding-top: 32px ;
    }
    .text{
      width: 80%;
      position: relative;
      top: -25px;
      box-shadow: rgb(102 102 102 / 40%) 10px 10px 10px 0px;
      padding: 1rem 1.5rem;
      margin: 0 auto;
      background: rgb(255, 255, 255);
      .card_title{
        color:$main;
        font-weight: bold;
      }
      .line{
        border-bottom: 2px solid $main;
        margin: 1rem auto;
        width: 1rem;
      }
    }
  }
  .informationForm{
    padding: 200px 0;
    background: rgb(255, 255, 255);
    .subtitle{
      color:black;
      font-weight: bold;
      font-size: 20px;
      margin: 2rem 0;
    }
    ul{
      max-width: 318px;
      margin: 3rem auto;
      padding: 0;
    }
    .information{
      .col-12{
        margin: 1rem 0;
        .d-block{
          text-align: left;
        }
      }
      .el-input__inner{
        height: 3rem;
      }
    }
    .terms_send{
      width: min-content;
      text-align: left;
      margin: 3rem auto;
      .el-button--primary{
        background-color: #2575ED;
        border-color:#2575ED
      }
    }
  }
  @media screen and (max-width:756px) {
    .banner{
      height: 350px;
      background-position: center center;
      background-image: url("~@/assets/event/mobile_banner.png");
    }
  }
}
</style>
